/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as Yup from 'yup';
import checkout from './form';

const {
  formField: { name, clientId, operatorId }
} = checkout;

const validations = [
  Yup.object().shape(
    {
      [name.name]: Yup.string().required(name.errorMsg),
      [clientId.name]: Yup.string().when(operatorId.name, {
        is: (fieldTwo) => !fieldTwo || fieldTwo.length === 0,
        then: Yup.string().required('At least one of the fields is required')
      }),
      [operatorId.name]: Yup.string().when(clientId.name, {
        is: (fieldOne) => !fieldOne || fieldOne.length === 0,
        then: Yup.string().required('At least one of the fields is required')
      })
    },
    [clientId.name, operatorId.name]
  )
];

export default validations;
