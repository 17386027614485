import { AbilityBuilder, createMongoAbility } from '@casl/ability';
export const getUserAbilities = (role, client = null) => {
  console.log(role, client);
  const { can, build } = new AbilityBuilder(createMongoAbility);
  can('read', 'session');
  if (role == 'admin' || role == 'client') {
    can('manage', 'game');
    can('manage', 'email_metrics');
    can('manage', 'operator');
    can('manage', 'user');
  }
  if (role == 'client' || role == 'admin' || role == 'operator') {
    can('manage', 'brand');
  }
  can('read', 'metric');
  can('manage', 'player');
  if (role == 'admin' && !client) {
    can('manage', 'all');
  }
  return build();
};
