import useAxios from 'hooks/useAxios';

export const getGames = async (limit = 20, page = 1) => {
  const api = useAxios();
  try {
    return (
      await api.get('/game', {
        params: { limit: limit, page: page }
      })
    ).data;
  } catch {
    return {
      data: [],
      meta: {
        totalItems: 0,
        itemCount: 0,
        itemsPerPage: 0,
        totalPages: 0,
        currentPage: 0
      }
    };
  }
};

export const getGame = async (id, limit = 20, page = 1) => {
  const api = useAxios();
  try {
    return (
      await api.get(`/game/${id}`, {
        params: { limit: limit, page: page }
      })
    ).data;
  } catch {
    return {
      data: [],
      meta: {
        totalItems: 0,
        itemCount: 0,
        itemsPerPage: 0,
        totalPages: 0,
        currentPage: 0
      }
    };
  }
};

export const updateGame = async (
  id,
  percentTake,
  minCrash,
  maxCrash,
  minPlayers,
  maxPlayers,
  takeOnLose,
  takingCap
) => {
  const api = useAxios();
  try {
    return (
      await api.patch(`/game/${id}`, {
        percentTake: Number(percentTake),
        minCrash: Number(minCrash),
        maxCrash: Number(maxCrash),
        minPlayers: Number(minPlayers),
        maxPlayers: Number(maxPlayers),
        takeOnLose: takeOnLose,
        takingCap: Number(takingCap)
      })
    ).data;
  } catch {
    return {
      data: [],
      meta: {
        totalItems: 0,
        itemCount: 0,
        itemsPerPage: 0,
        totalPages: 0,
        currentPage: 0
      }
    };
  }
};

export const addAvatar = async (gameId, name, image, position) => {
  const api = useAxios();
  try {
    const formData = new FormData();
    formData.append('gameId', gameId);
    formData.append('name', name);
    formData.append('image', image);
    formData.append('position', position);
    return await api.post(`/avatar`, formData);
  } catch {
    return {
      data: [],
      meta: {
        totalItems: 0,
        itemCount: 0,
        itemsPerPage: 0,
        totalPages: 0,
        currentPage: 0
      }
    };
  }
};

export const updateAvatar = async (id, gameId, name, image, position) => {
  const api = useAxios();
  try {
    const formData = new FormData();
    gameId && formData.append('gameId', gameId);
    name && formData.append('name', name);
    image !== undefined && formData.append('image', image);
    position && formData.append('position', position);
    return await api.patch(`/avatar/${id}`, formData);
  } catch {
    return {
      data: [],
      meta: {
        totalItems: 0,
        itemCount: 0,
        itemsPerPage: 0,
        totalPages: 0,
        currentPage: 0
      }
    };
  }
};

export const deleteAvatar = async (id) => {
  const api = useAxios();
  try {
    return await api.delete(`/avatar/${id}`);
  } catch {
    return {
      data: [],
      meta: {
        totalItems: 0,
        itemCount: 0,
        itemsPerPage: 0,
        totalPages: 0,
        currentPage: 0
      }
    };
  }
};

export const addBetLimit = async (gameId, minBet, maxBet, singleBet) => {
  const api = useAxios();
  try {
    return await api.post(`/bet-limit`, {
      gameId: Number(gameId),
      minBet: Number(minBet),
      maxBet: Number(maxBet),
      singleBet: Number(singleBet)
    });
  } catch {
    return {
      data: [],
      meta: {
        totalItems: 0,
        itemCount: 0,
        itemsPerPage: 0,
        totalPages: 0,
        currentPage: 0
      }
    };
  }
};

export const updateMinMaxBetLimit = async (id, gameId, minBet, maxBet) => {
  const api = useAxios();
  try {
    return await api.patch(`/bet-limit/${id}`, {
      gameId: Number(gameId),
      minBet: Number(minBet),
      maxBet: Number(maxBet)
    });
  } catch {
    return {
      data: [],
      meta: {
        totalItems: 0,
        itemCount: 0,
        itemsPerPage: 0,
        totalPages: 0,
        currentPage: 0
      }
    };
  }
};

export const updateSingleBetLimit = async (id, gameId, singleBet) => {
  const api = useAxios();
  try {
    return await api.patch(`/bet-limit/${id}`, {
      gameId: Number(gameId),
      singleBet: Number(singleBet)
    });
  } catch {
    return {
      data: [],
      meta: {
        totalItems: 0,
        itemCount: 0,
        itemsPerPage: 0,
        totalPages: 0,
        currentPage: 0
      }
    };
  }
};

export const deleteBetLimit = async (id) => {
  const api = useAxios();
  try {
    return await api.delete(`/bet-limit/${id}`, {});
  } catch {
    return {
      data: [],
      meta: {
        totalItems: 0,
        itemCount: 0,
        itemsPerPage: 0,
        totalPages: 0,
        currentPage: 0
      }
    };
  }
};

export const createAccessCodes = async (quantity, validityStart, validityEnd, gameId) => {
  const api = useAxios();
  try {
    return await api.post(`/access-code/bulk`, {
      quantity: Number(quantity),
      validityStart,
      validityEnd,
      gameId: Number(gameId)
    });
  } catch (err) {
    return err;
  }
};
