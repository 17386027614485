import {
  addAvatar,
  addBetLimit,
  createAccessCodes,
  deleteAvatar,
  getGame,
  updateAvatar,
  updateGame
} from 'services/games';
import { Socket, io } from 'socket.io-client';
import { useEffect, useState } from 'react';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'components/DashboardNavbar';
import MDBox from 'components/MDBox';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import MDButton from 'components/MDButton';
import BetLimit from './components/BetLimit';
import { Button } from '@mui/base';

function Game() {
  let gameId = window.location.pathname.split('/').pop();
  const [game, setGame] = useState();
  const [percentTake, setPercentTake] = useState();
  const [takeOnLose, setTakeOnLose] = useState();
  const [minCrash, setMinCrash] = useState();
  const [maxCrash, setMaxCrash] = useState();
  const [minPlayers, setMinPlayers] = useState();
  const [maxPlayers, setMaxPlayers] = useState();
  const [clientId, setClientId] = useState();
  const [clientName, setClientName] = useState();
  const [callbackUrl, setCallbackUrl] = useState();
  const [betLimits, setBetLimits] = useState();
  const [avatars, setAvatars] = useState();
  const [takingCap, setTakingCap] = useState();

  const [openEditAvatar, setOpenEditAvatar] = useState(false);
  const [editAvatarId, setEditAvatarId] = useState();
  const [editAvatarName, setEditAvatarName] = useState();
  const [editAvatarImage, setEditAvatarImage] = useState();
  const [editAvatarPosition, setEditAvatarPosition] = useState();
  const handleOpenEditAvatar = () => setOpenEditAvatar(true);
  const handleCloseEditAvatar = () => setOpenEditAvatar(false);

  function handleEditAvatarChange(event) {
    setEditAvatarImage(event.target.files[0]);
  }

  const [openAddAvatar, setOpenAddAvatar] = useState(false);
  const [addAvatarName, setAddAvatarName] = useState();
  const [addAvatarImage, setAddAvatarImage] = useState();
  const [addAvatarPosition, setAddAvatarPosition] = useState();
  const handleOpenAddAvatar = () => setOpenAddAvatar(true);
  const handleCloseAddAvatar = () => setOpenAddAvatar(false);

  function handleAddAvatarChange(event) {
    setAddAvatarImage(event.target.files[0]);
  }

  const [openAddBetLimit, setOpenAddBetLimit] = useState(false);
  const [newLimitMinBet, setNewLimitMinBet] = useState();
  const [newLimitMaxBet, setNewLimitMaxBet] = useState();
  const [newLimitSingleBet, setNewLimitSingleBet] = useState();
  const handleOpenAddBetLimit = () => setOpenAddBetLimit(true);
  const handleCloseAddBetLimit = () => {
    setOpenAddBetLimit(false);
    setNewLimitMinBet(null);
    setNewLimitMaxBet(null);
  };

  const [openDeleteAvatar, setOpenDeleteAvatar] = useState();
  const [deleteAvatarId, setDeleteAvatarId] = useState();
  const handleCloseDeleteAvatar = () => setOpenDeleteAvatar(false);
  const handleOpenDeleteAvatar = () => setOpenDeleteAvatar(true);

  const [updateInfo, setUpdateInfo] = useState();
  const [rerender, setRerender] = useState(false);
  const [limitType, setLimitType] = useState('none');

  const [activeSessions, setActiveSessions] = useState(null);

  const [quantity, setQuantity] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [tokens, setTokens] = useState([]);
  const [modal, setModal] = useState(false);

  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    if (!tokens || tokens.length === 0) return;

    const tokenText = tokens.map((t) => t?.code).join('\n'); // Join tokens with line breaks
    navigator.clipboard
      .writeText(tokenText)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset "Copied!" after 2s
      })
      .catch((err) => console.error('Failed to copy:', err));
  };

  useEffect(() => {
    console.log('useEffect', process.env.REACT_APP_SOCKET_URL);
    const value = `; ${document.cookie}`;
    let parts = value.split(`; access_token=`);
    if (parts.length === 2) {
      parts = parts.pop().split(';').shift();
    }
    const token = parts;
    if (!token || !gameId || !betLimits) {
      return;
    }
    const socket = io(process.env.REACT_APP_SOCKET_URL, {
      auth: {
        token: token
      },
      path: '/backoffice'
    });
    socket.on('active-sessions', (data) => {
      console.log(data, betLimits);
      setActiveSessions(data);
    });
    console.log('socket', gameId);
    socket.on('connect', () => {
      socket.emit('listen-for-game', gameId);
    });
  }, [gameId, betLimits]);

  useEffect(() => {
    setTimeout(() => {
      setUpdateInfo(false);
    }, 6000);
  }, [updateInfo]);

  useEffect(() => {
    getGame(gameId).then((res) => setGame(res));
  }, []);

  useEffect(() => {
    setPercentTake(game?.percentTake ? game.percentTake : 0);
    setMaxCrash(game?.maxCrash ? game.maxCrash : 0);
    setMinCrash(game?.minCrash ? game.minCrash : 0);
    setMaxPlayers(game?.maxPlayers ? game.maxPlayers : 0);
    setMinPlayers(game?.minPlayers ? game.minPlayers : 0);
    setClientId(game?.client?.id ? game.client.id : 0);
    setClientName(game?.client?.name ? game.client.name : 0);
    setCallbackUrl(game?.client?.callbackUrl ? game.client.callbackUrl : 0);
    setBetLimits(game?.betLimits ? game.betLimits : []);
    setAvatars(game?.avatars ? game.avatars : []);
    setTakeOnLose(game?.takeOnLose ? game.takeOnLose : false);
    setTakingCap(game?.takingCap ? game.takingCap : 0);

    betLimits && betLimits[0]?.singleBet ? setLimitType('single') : setLimitType('minMax');
  }, [game]);

  useEffect(() => {
    getGame(gameId).then((res) => setGame(res));
  }, [rerender]);

  async function handleSubmit(e) {
    // stop page from refreshing
    e.preventDefault();
    const res = await createAccessCodes(quantity, startDate, endDate, gameId);

    setTokens(res?.data);
    setQuantity('');
    setStartDate('');
    setEndDate('');
    handleModal();
  }

  function handleModal() {
    setModal(!modal);
  }

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox px={2}>
          <MDTypography sx={{ fontWeight: 600 }}>{game?.name ? game.name : '-'}</MDTypography>
          <Grid container sx={{ marginTop: '10px' }} spacing={3}>
            <Grid item xs={4}>
              <Grid item xs={12}>
                <MDTypography sx={{ fontSize: '16px', fontWeight: '600' }}>Client Information</MDTypography>
                <MDBox
                  sx={{
                    border: '0.5px solid white',
                    borderRadius: '10px',
                    padding: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    background: '#27314f',
                    marginTop: '10px'
                  }}
                >
                  <MDBox sx={{ display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'space-between' }}>
                    <label style={{ fontSize: '14px', color: '#FFF', fontWeight: '600' }}>ID</label>
                    <MDTypography sx={{ fontSize: '14px', color: '#FFF' }}>{clientId}</MDTypography>
                  </MDBox>
                  <MDBox sx={{ display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'space-between' }}>
                    <label style={{ fontSize: '14px', color: '#FFF', fontWeight: '600' }}>Name</label>
                    <MDTypography sx={{ fontSize: '14px', color: '#FFF' }}>{clientName}</MDTypography>
                  </MDBox>
                  <MDBox sx={{ display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'space-between' }}>
                    <label style={{ fontSize: '14px', color: '#FFF', fontWeight: '600' }}>Callback URL</label>
                    <MDTypography sx={{ fontSize: '14px', color: '#FFF' }}>{callbackUrl}</MDTypography>
                  </MDBox>
                </MDBox>
              </Grid>
              <Grid item xs={12} sx={{ marginTop: '30px' }}>
                <MDTypography sx={{ fontSize: '16px', fontWeight: '600' }}>Game Information</MDTypography>
                <MDBox
                  sx={{
                    border: '0.5px solid white',
                    borderRadius: '10px',
                    padding: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    background: '#27314f',
                    marginTop: '10px'
                  }}
                >
                  <MDBox sx={{ display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'space-between' }}>
                    <label style={{ fontSize: '14px', color: '#FFF', fontWeight: '600' }}>Take on Lose</label>
                    <input
                      type='checkbox'
                      checked={takeOnLose !== undefined ? takeOnLose : false}
                      onChange={(e) => {
                        setTakeOnLose(e.target.checked);
                      }}
                      style={{
                        width: '22px',
                        height: '22px',
                        marginRight: '2px'
                      }}
                    ></input>
                  </MDBox>
                  <MDBox sx={{ display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'space-between' }}>
                    <label style={{ fontSize: '14px', color: '#FFF', fontWeight: '600' }}>Percent Take</label>
                    <input
                      type='number'
                      value={percentTake !== undefined ? percentTake : 0}
                      onChange={(e) => {
                        setPercentTake(e.target.value);
                      }}
                      style={{
                        background: '#FFF',
                        border: '2px solid #333333',
                        color: '#000',
                        borderRadius: '8px',
                        padding: '10px 15px'
                      }}
                    ></input>
                  </MDBox>
                  <MDBox sx={{ display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'space-between' }}>
                    <label style={{ fontSize: '14px', color: '#FFF', fontWeight: '600' }}>Taking Cap</label>
                    <input
                      type='number'
                      value={takingCap !== undefined ? takingCap : 0}
                      onChange={(e) => {
                        setTakingCap(e.target.value);
                      }}
                      style={{
                        background: '#FFF',
                        border: '2px solid #333333',
                        color: '#000',
                        borderRadius: '8px',
                        padding: '10px 15px'
                      }}
                    ></input>
                  </MDBox>
                  <MDBox sx={{ display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'space-between' }}>
                    <label style={{ fontSize: '14px', color: '#FFF', fontWeight: '600' }}>Min Crash</label>
                    <input
                      type='number'
                      value={minCrash !== undefined ? minCrash : 0}
                      onChange={(e) => {
                        setMinCrash(e.target.value);
                      }}
                      style={{
                        background: '#FFF',
                        border: '2px solid #333333',
                        color: '#000',
                        borderRadius: '8px',
                        padding: '10px 15px'
                      }}
                    ></input>
                  </MDBox>
                  <MDBox sx={{ display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'space-between' }}>
                    <label style={{ fontSize: '14px', color: '#FFF', fontWeight: '600' }}>Max Crash</label>
                    <input
                      type='number'
                      value={maxCrash !== undefined ? maxCrash : 0}
                      onChange={(e) => {
                        setMaxCrash(e.target.value);
                      }}
                      style={{
                        background: '#FFF',
                        border: '2px solid #333333',
                        color: '#000',
                        borderRadius: '8px',
                        padding: '10px 15px'
                      }}
                    ></input>
                  </MDBox>
                  <MDBox sx={{ display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'space-between' }}>
                    <label style={{ fontSize: '14px', color: '#FFF', fontWeight: '600' }}>Min Players</label>
                    <input
                      type='number'
                      value={minPlayers !== undefined ? minPlayers : 0}
                      onChange={(e) => {
                        setMinPlayers(e.target.value);
                      }}
                      style={{
                        background: '#FFF',
                        border: '2px solid #333333',
                        color: '#000',
                        borderRadius: '8px',
                        padding: '10px 15px'
                      }}
                    ></input>
                  </MDBox>
                  <MDBox sx={{ display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'space-between' }}>
                    <label style={{ fontSize: '14px', color: '#FFF', fontWeight: '600' }}>Max Players</label>
                    <input
                      type='number'
                      value={maxPlayers !== undefined ? maxPlayers : 0}
                      onChange={(e) => {
                        setMaxPlayers(e.target.value);
                      }}
                      style={{
                        background: '#FFF',
                        border: '2px solid #333333',
                        color: '#000',
                        borderRadius: '8px',
                        padding: '10px 15px'
                      }}
                    ></input>
                  </MDBox>
                  {updateInfo && (
                    <MDBox sx={{ fontSize: '14px', textAlign: 'center', color: '#fff' }}>
                      Game information was updated successfully.
                    </MDBox>
                  )}
                  <MDButton
                    color={'info'}
                    onClick={() =>
                      updateGame(
                        gameId,
                        percentTake,
                        minCrash,
                        maxCrash,
                        minPlayers,
                        maxPlayers,
                        takeOnLose,
                        takingCap
                      ).then(() => setUpdateInfo(true))
                    }
                  >
                    Save changes
                  </MDButton>
                </MDBox>
              </Grid>
            </Grid>

            <Grid item xs={8}>
              <Grid item xs={12}>
                <MDTypography sx={{ fontSize: '16px', fontWeight: '600' }}>Bet Limits</MDTypography>
                <MDBox
                  sx={{
                    border: '0.5px solid white',
                    borderRadius: '10px',
                    padding: '20px',
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '20px',
                    background: '#27314f',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    marginTop: '10px'
                  }}
                >
                  {betLimits &&
                    betLimits.map((limit) => {
                      return (
                        <BetLimit
                          id={limit.id}
                          minBet={limit.minBet}
                          maxBet={limit.maxBet}
                          singleBet={limit.singleBet}
                          gameId={gameId}
                        ></BetLimit>
                      );
                    })}
                  <MDBox sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <MDButton color={'info'} sx={{ width: '30%', alignSelf: 'center' }} onClick={handleOpenAddBetLimit}>
                      Add new
                    </MDButton>
                  </MDBox>
                </MDBox>
                {modal && (
                  <Dialog
                    open={handleModal}
                    onClose={handleModal}
                    aria-labelledby='alert-dialog-title'
                    aria-describedby='alert-dialog-description'
                  >
                    <DialogTitle
                      id='alert-dialog-title'
                      sx={{ width: '100%', textAlign: 'center' }}
                    >{`Generated access tokens`}</DialogTitle>
                    <MDButton variant='contained' color='secondary' onClick={handleCopy} sx={{ marginTop: '10px' }}>
                      {copied ? 'Copied!' : 'Copy All'}
                    </MDButton>
                    <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '5px', padding: '20px 30px' }}>
                      {tokens?.map((t, i) => (
                        <MDTypography key={i}>{t?.code}</MDTypography>
                      ))}
                    </DialogContent>
                  </Dialog>
                )}
                <Grid container gap={5}>
                  <Grid item xs={8} md={12}>
                    <MDTypography sx={{ fontSize: '16px', fontWeight: '600', marginTop: '10px' }}>
                      Active sessions
                    </MDTypography>
                    <MDBox
                      sx={{
                        border: '0.5px solid white',
                        borderRadius: '10px',
                        padding: '20px',
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '20px',
                        background: '#27314f',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        marginTop: '10px'
                      }}
                    >
                      <MDBox
                        sx={{
                          display: 'flex',
                          gap: '10px',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          width: '100%'
                        }}
                      >
                        <label style={{ fontSize: '14px', color: '#FFF', fontWeight: '600' }}>Active servers</label>
                        <MDTypography sx={{ fontSize: '14px', color: '#FFF' }}>
                          {(activeSessions?.activeServersForGame != undefined &&
                            Object.entries(activeSessions?.activeServersForGame)
                              ?.map(([server, p]) => server)
                              ?.map((val, i) => {
                                return (
                                  <span>
                                    {val}
                                    {i < activeSessions?.activeServersForGame?.size - 1 ? ', ' : ''}
                                  </span>
                                );
                              })) ||
                            '-'}
                        </MDTypography>
                      </MDBox>
                      <MDBox
                        sx={{
                          display: 'flex',
                          gap: '10px',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          width: '100%'
                        }}
                      >
                        <label style={{ fontSize: '14px', color: '#FFF', fontWeight: '600' }}>Currently active</label>
                        <MDTypography sx={{ fontSize: '14px', color: '#FFF' }}>
                          {activeSessions?.activeSessionsGame || 0}
                        </MDTypography>
                      </MDBox>
                      <label style={{ fontSize: '14px', color: '#FFF', fontWeight: '600' }}>
                        Currently active per bet
                      </label>
                      {betLimits &&
                        betLimits.map((limit) => {
                          return (
                            <MDBox
                              sx={{
                                display: 'flex',
                                gap: '10px',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '100%'
                              }}
                            >
                              <label style={{ fontSize: '14px', color: '#FFF', fontWeight: '600' }}>
                                Bet: {limit.singleBet ? limit.singleBet : `${limit.minBet} - ${limit.maxBet}`}
                              </label>
                              <MDTypography sx={{ fontSize: '14px', color: '#FFF' }}>
                                Active users:{' '}
                                {activeSessions?.activeSessionsPerLimit?.find((bet) => bet.id === limit.id)?.count || 0}
                              </MDTypography>
                            </MDBox>
                          );
                        })}
                    </MDBox>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      // maxWidth: '400px',
                      margin: '0 auto',
                      padding: '20px',
                      border: '1px solid #ddd',
                      borderRadius: '5px'
                    }}
                  >
                    <MDTypography variant='h3'>Generate Access Tokens</MDTypography>
                    <form onSubmit={(e) => handleSubmit(e)}>
                      {/* ✅ Game ID (Read-Only) */}
                      <div>
                        <MDTypography>
                          <label>Game ID:</label>
                        </MDTypography>
                        <input
                          type='text'
                          value={gameId}
                          disabled
                          style={{ width: '100%', padding: '8px', marginBottom: '10px' }}
                        />
                      </div>

                      {/* ✅ Quantity Input */}
                      <div>
                        <MDTypography>
                          <label>Quantity:</label>
                        </MDTypography>
                        <input
                          type='number'
                          value={quantity}
                          onChange={(e) => setQuantity(e.target.value)}
                          required
                          style={{ width: '100%', padding: '8px', marginBottom: '10px' }}
                        />
                      </div>

                      {/* ✅ Start Date Input */}
                      <div>
                        <MDTypography>
                          <label>Start Date:</label>
                        </MDTypography>
                        <input
                          type='datetime-local'
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                          required
                          style={{ width: '100%', padding: '8px', marginBottom: '10px' }}
                        />
                      </div>

                      {/* ✅ End Date Input */}
                      <div>
                        <MDTypography>
                          <label>End Date:</label>
                        </MDTypography>
                        <input
                          type='datetime-local'
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                          required
                          style={{ width: '100%', padding: '8px', marginBottom: '10px' }}
                        />
                      </div>

                      {/* ✅ Submit Button */}
                      <button
                        type='submit'
                        disabled={loading}
                        style={{
                          width: '100%',
                          padding: '10px',
                          background: loading ? '#ccc' : '#007bff',
                          color: '#fff',
                          border: 'none',
                          cursor: loading ? 'not-allowed' : 'pointer'
                        }}
                      >
                        {loading ? 'Submitting...' : 'Submit'}
                      </button>
                    </form>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ marginTop: '20px' }}>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <MDTypography sx={{ fontSize: '16px', fontWeight: '600' }}>Avatars</MDTypography>
              <MDButton color='info' onClick={handleOpenAddAvatar}>
                Add new avatar
              </MDButton>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexWrap: 'wrap' }}
            >
              {avatars && avatars.length > 0 ? (
                avatars.map((avatar) => {
                  return (
                    <Grid item xs={3} sx={{ padding: '15px' }}>
                      <MDBox
                        sx={{
                          background: '#27314f',
                          borderRadius: '10px',
                          border: '0.5px solid #fff',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          paddingBottom: '15px'
                        }}
                      >
                        <MDTypography
                          sx={{
                            padding: '10px 0',
                            width: '100%',
                            textAlign: 'center',
                            background: '#121c35',
                            borderRadius: '10px 10px 0 0',
                            fontWeight: '600',
                            fontSize: '16px',
                            color: '#fff'
                          }}
                        >
                          {avatar.name}
                        </MDTypography>
                        <img src={avatar.image} style={{ width: '200px' }}></img>
                        <MDBox sx={{ display: 'flex', padding: '10px', gap: '10px' }}>
                          <MDButton
                            onClick={() => {
                              handleOpenEditAvatar();
                              setEditAvatarId(avatar.id);
                              setEditAvatarName(avatar.name);
                              setEditAvatarPosition(avatar.position);
                            }}
                          >
                            Edit Avatar
                          </MDButton>
                          <MDButton
                            onClick={() => {
                              handleOpenDeleteAvatar();
                              setDeleteAvatarId(avatar.id);
                            }}
                          >
                            Delete Avatar
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </Grid>
                  );
                })
              ) : (
                <MDBox
                  sx={{
                    width: '100%',
                    textAlign: 'center',
                    paddingBottom: '30px'
                  }}
                >
                  <MDTypography sx={{ fontSize: '14px' }}>No avatars available.</MDTypography>
                </MDBox>
              )}
            </Grid>
          </Grid>
        </MDBox>

        <Dialog
          open={openEditAvatar}
          onClose={handleCloseEditAvatar}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title' sx={{ width: '100%', textAlign: 'center' }}>{`Edit avatar`}</DialogTitle>
          <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '5px', padding: '20px 30px' }}>
            <MDTypography sx={{ fontSize: '16px' }}>Avatar name</MDTypography>
            <input
              type='text'
              value={editAvatarName}
              onChange={(e) => {
                setEditAvatarName(e.target.value);
              }}
              style={{ padding: '10px 15px', borderRadius: '8px', border: 'none', width: '500px' }}
            />
            <MDTypography sx={{ fontSize: '16px' }}>Avatar Position</MDTypography>
            <input
              type='text'
              value={editAvatarPosition}
              onChange={(e) => {
                setEditAvatarPosition(e.target.value);
              }}
              style={{ padding: '10px 15px', borderRadius: '8px', border: 'none', width: '500px' }}
            />
            <MDTypography sx={{ fontSize: '16px', marginTop: '10px' }}>Avatar image</MDTypography>
            <input type='file' onChange={handleEditAvatarChange} />
          </DialogContent>
          <DialogActions>
            <MDButton variant='text' onClick={handleCloseEditAvatar}>
              Close
            </MDButton>
            <MDButton
              color='info'
              onClick={() => {
                updateAvatar(editAvatarId, gameId, editAvatarName, editAvatarImage, editAvatarPosition);
                handleCloseEditAvatar();
              }}
            >
              Save
            </MDButton>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openAddAvatar}
          onClose={handleCloseAddAvatar}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle
            id='alert-dialog-title'
            sx={{ width: '100%', textAlign: 'center' }}
          >{`Add new avatar`}</DialogTitle>
          <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '5px', padding: '20px 30px' }}>
            <MDTypography sx={{ fontSize: '16px' }}>Avatar name</MDTypography>
            <input
              type='text'
              value={addAvatarName}
              onChange={(e) => {
                setAddAvatarName(e.target.value);
              }}
              style={{ padding: '10px 15px', borderRadius: '8px', border: 'none', width: '500px' }}
            />
            <MDTypography sx={{ fontSize: '16px' }}>Avatar Position</MDTypography>
            <input
              type='text'
              value={addAvatarPosition}
              onChange={(e) => {
                setAddAvatarPosition(e.target.value);
              }}
              style={{ padding: '10px 15px', borderRadius: '8px', border: 'none', width: '500px' }}
            />
            <MDTypography sx={{ fontSize: '16px', marginTop: '10px' }}>Avatar image</MDTypography>
            <input type='file' onChange={handleAddAvatarChange} />
          </DialogContent>
          <DialogActions>
            <MDButton variant='text' onClick={handleCloseAddAvatar}>
              Close
            </MDButton>
            <MDButton
              color='info'
              onClick={() => {
                addAvatar(gameId, addAvatarName, addAvatarImage, addAvatarPosition);
                handleCloseAddAvatar();
              }}
            >
              Save
            </MDButton>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openAddBetLimit}
          onClose={handleCloseAddBetLimit}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle
            id='alert-dialog-title'
            sx={{ width: '100%', textAlign: 'center' }}
          >{`Add New Bet Limit`}</DialogTitle>
          <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '5px', padding: '20px 30px' }}>
            {(limitType === 'minMax' || limitType === 'none') && (
              <>
                <MDTypography sx={{ fontSize: '16px' }}>Min Bet</MDTypography>
                <input
                  type='number'
                  value={newLimitMinBet}
                  onChange={(e) => {
                    setNewLimitMinBet(e.target.value);
                  }}
                  style={{ padding: '10px 15px', borderRadius: '8px', border: 'none', width: '500px' }}
                />
                <MDTypography sx={{ fontSize: '16px', marginTop: '10px' }}>Max Bet</MDTypography>
                <input
                  type='number'
                  value={newLimitMaxBet}
                  onChange={(e) => {
                    setNewLimitMaxBet(e.target.value);
                  }}
                  style={{ padding: '10px 15px', borderRadius: '8px', border: 'none', width: '500px' }}
                />
              </>
            )}
            {(limitType === 'single' || limitType === 'none') && (
              <>
                <MDTypography sx={{ fontSize: '16px' }}>Single Bet</MDTypography>
                <input
                  type='number'
                  value={newLimitSingleBet}
                  onChange={(e) => {
                    setNewLimitSingleBet(e.target.value);
                  }}
                  style={{ padding: '10px 15px', borderRadius: '8px', border: 'none', width: '500px' }}
                />
              </>
            )}
          </DialogContent>
          <DialogActions>
            <MDButton variant='text' onClick={handleCloseAddBetLimit}>
              Close
            </MDButton>
            <MDButton
              color='info'
              onClick={() => {
                addBetLimit(gameId, newLimitMinBet, newLimitMaxBet, newLimitSingleBet);
                handleCloseAddBetLimit();
              }}
            >
              Add
            </MDButton>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openDeleteAvatar}
          onClose={handleCloseDeleteAvatar}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle
            id='alert-dialog-title'
            sx={{ width: '100%', textAlign: 'center' }}
          >{`Are you sure you want to delete this avatar?`}</DialogTitle>
          <DialogActions>
            <MDButton
              variant='text'
              color='error'
              onClick={() => {
                deleteAvatar(deleteAvatarId);
                setRerender(!rerender);
                handleCloseDeleteAvatar();
              }}
            >
              Yes
            </MDButton>
            <MDButton
              variant='text'
              onClick={() => {
                handleCloseDeleteAvatar();
              }}
            >
              No
            </MDButton>
          </DialogActions>
        </Dialog>
      </DashboardLayout>
    </>
  );
}

export default Game;
