/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useContext, useEffect, useState } from 'react';
// prop-type is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Grid from '@mui/material/Grid';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// NewUser page components
import FormField from 'layouts/operator-management/components/FormField';
import { Autocomplete, Checkbox, TextField } from '@mui/material';
import { ErrorMessage, Field } from 'formik';
import MDInput from 'components/MDInput';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useMaterialUIController } from 'context';
import { getAutocompleteClients } from 'services/clients';
function OperatorInfo({ formData }) {
  const [controller] = useMaterialUIController();
  const { user, role } = controller;
  const { formField, values, errors, touched, setFieldValue, isSubmitting } = formData;
  const { name, clientId } = formField;
  let { name: nameV, clientId: clientIdV } = values;
  const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
  const checkedIcon = <CheckBoxIcon fontSize='small' />;

  useEffect(() => {
    if (user && role == 'client') {
      values.clientId = user.client.id;
    }
  }, [user, values]);

  const [clientOptions, setClientOptions] = useState([]);
  const [clientValue, setClientValue] = useState('');

  const handleClientInput = (event) => {
    if (event.target.value.length >= 2) {
      getAutocompleteClients(event.target.value).then((players) => {
        setClientOptions(players);
      });
    } else if (event.target.value.length < 2) {
      setClientOptions([]);
    }
  };
  console.log(errors, clientIdV);
  return (
    <MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={24} sm={12}>
            <FormField
              type={name.type}
              label={name.label}
              name={name.name}
              value={nameV}
              placeholder={name.placeholder}
              error={errors.name && touched.name}
              success={nameV && !errors.name}
            />
          </Grid>
          {user && role !== 'client' && (
            <Grid item xs={24} sm={12}>
              <MDBox>
                <Autocomplete
                  limitTags={2}
                  options={clientOptions}
                  value={clientValue}
                  onChange={(e, value) => {
                    if (!value) {
                      setFieldValue(clientId.name, '');
                      setClientValue('');
                      return;
                    }
                    setFieldValue(clientId.name, value.value);
                    setClientValue(value.name);
                  }}
                  isOptionEqualToValue={(option, value) => option.name === value}
                  getOptionLabel={(option) => (option?.label ? option.label : option)}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                      {option.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <>
                      <Field
                        type={clientId.type}
                        {...params}
                        as={MDInput}
                        name={clientId.name}
                        value={clientIdV}
                        variant='standard'
                        label={clientId.label}
                        error={errors.clientId && touched.clientId}
                        success={clientIdV.length > 0 && !errors.clientId}
                        onChange={handleClientInput}
                        fullWidth
                      />
                      <MDBox mt={0.75}>
                        <MDTypography component='div' variant='caption' color='error' fontWeight='regular'>
                          <ErrorMessage name={clientId.name} />
                        </MDTypography>
                      </MDBox>
                    </>
                  )}
                />
              </MDBox>
            </Grid>
          )}
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for ClientInfo
OperatorInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired
};

export default OperatorInfo;
