import { Grid, Input } from '@mui/material';
import MDButton from 'components/MDButton';
import { useEffect, useState } from 'react';

export default function Search({ filter, setFilter }) {
  const [searchTerm, setSearchTerm] = useState(filter); // Local state for debouncing

  useEffect(() => {
    const delay = setTimeout(() => {
      setFilter(searchTerm);
    }, 1000);

    return () => clearTimeout(delay);
  }, [searchTerm, setFilter]);

  return (
    <Grid gap={10} container>
      <Input value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} placeholder='Player...'></Input>
      {/* <MDButton>Search</MDButton> */}
    </Grid>
  );
}
