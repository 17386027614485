import { Button } from '@mui/base';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import MDButton from 'components/MDButton';
import { useState } from 'react';

const metricColumnData = [
  {
    Header: 'Metric type',
    accessor: 'metricType'
  },
  {
    Header: 'Amount',
    accessor: 'amount'
  },
  {
    Header: 'Metric date',
    accessor: 'createdAt'
  },
  {
    Header: 'Player Username',
    accessor: 'playerUsername'
  },
  {
    Header: 'Player Nickname',
    accessor: 'playerNickname',
    Cell: ({ value }) => {
      return value ? value : '-';
    }
  },
  {
    Header: 'Game',
    accessor: 'game'
  },
  {
    Header: 'Bet range',
    accessor: 'betRange',
    Cell: ({ value }) => {
      return value?.minBet !== null && value?.maxBet !== null
        ? `${value.min} - ${value.max}`
        : value?.singleBet !== null
        ? value.singleBet
        : '-';
    }
  },
  {
    Header: 'Currency',
    accessor: 'currency'
  },
  {
    Header: 'Client',
    accessor: 'client'
  },
  {
    Header: 'Transaction Id',
    accessor: 'transactionId',
    Cell: ({ value }) => {
      return value ? value : '-';
    }
  },
  // {
  //   Header: 'Session Date',
  //   accessor: 'sessionDate'
  // },
  {
    Header: 'Country',
    accessor: 'sessionCountry',
    Cell: ({ value }) => {
      return value ? value : '-';
    }
  },
  {
    Header: 'Base Amount',
    accessor: 'baseAmount',
    Cell: ({ value }) => {
      return value ? value : '-';
    }
  },
  {
    Header: 'Base Rake Amount',
    accessor: 'baseRakeAmount',
    Cell: ({ value }) => {
      return value ? value : '-';
    }
  },
  {
    Header: 'Base Currency',
    accessor: 'baseCurrency',
    Cell: ({ value }) => {
      return value ? value : '-';
    }
  },
  {
    Header: 'Session',
    accessor: 'session',
    Cell: ({ value }) => {
      return value.id ? value.id : '-';
    }
  },
  {
    Header: 'Round',
    accessor: 'jetpackRound',
    Cell: ({ value }) => {
      const [openModal, setOpenModal] = useState(false);
      return value?.id ? (
        <>
          <div
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={() => {
              setOpenModal(true);
            }}
          >
            {value.id}
          </div>
          <Dialog
            open={openModal}
            onClose={() => setOpenModal(false)}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            maxWidth='md'
            fullWidth
          >
            <DialogTitle id='alert-dialog-title'>{'Round details'}</DialogTitle>
            <DialogContent>
              <div>
                <strong>ID:</strong> {value.id}
              </div>
              <div>
                <strong>Hash:</strong> {value.hash}
              </div>
              <div>
                <strong>Round Bet:</strong> {value.roundBet}
              </div>
              <div>
                <strong>Round Win:</strong> {value.roundWin ?? '-'}
              </div>
              <div>
                <strong>Round Winner ID:</strong> {value?.roundWinner?.id ?? '-'}
              </div>
              <div>
                <strong>Round Winner Meters:</strong> {value?.roundWinnerCrash ?? '-'}
              </div>
              <div>
                <strong>Player IDs:</strong> {value?.players?.map((p) => p.id).join(', ') ?? '-'}
              </div>
              <div>
                <strong>Round Crash:</strong> {value.roundCrash}
              </div>
              <div>
                <strong>Round Player Count:</strong> {value.roundPlayerCount}
              </div>
              <div>
                <strong>Table ID:</strong> {value.tableId}
              </div>
              <div>
                <strong>Created At:</strong> {new Date(value.createdAt).toLocaleString()}
              </div>
            </DialogContent>
            <DialogActions>
              <MDButton onClick={() => setOpenModal(false)} color='inherit'>
                Close
              </MDButton>
            </DialogActions>
          </Dialog>
        </>
      ) : (
        '-'
      );
    }
  }
];
export default metricColumnData;
