import DataTablePage from 'components/DataTablePage';
import { Navigate } from 'react-router-dom';
import { Can } from 'context';
import { getPlayers } from 'services/players';
import { playerColumnData } from 'data/playerColumnData';
import { useState } from 'react';
import Search from './Search';

function Players() {
  const [filters, setFilters] = useState('');
  return (
    <>
      <Can I='read' a='player'>
        <DataTablePage
          title='Players'
          canSearch
          canFilter
          fetchData={getPlayers}
          queryKey='players'
          columnData={playerColumnData}
          object={'game'}
          noActions={true}
          filters={filters}
          filtersComponent={<Search filter={filters} setFilter={setFilters} />}
        />
      </Can>
      <Can not I='read' a='player'>
        <Navigate to='/dashboard' replace />
      </Can>
    </>
  );
}

export default Players;
