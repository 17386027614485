/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useState } from 'react';
// prop-type is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Grid from '@mui/material/Grid';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// NewUser page components
import FormField from 'layouts/user-management/components/FormField';
import { Autocomplete, TextField, Checkbox } from '@mui/material';
import { ErrorMessage, Field } from 'formik';
import MDInput from 'components/MDInput';
import { getAutocompleteClients } from 'services/clients';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { getAutocompleteOperators } from 'services/operators';
import { getAutocompleteBrands } from 'services/brands';
import { useMaterialUIController } from 'context';

function UserInfo({ formData }) {
  const [controller] = useMaterialUIController();
  const { role: userRole, client } = controller;
  const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
  const checkedIcon = <CheckBoxIcon fontSize='small' />;
  const { formField, values, errors, touched, setFieldValue, isSubmitting } = formData;
  const { firstName, lastName, role, email, password, repeatPassword, clientId, brandId, operatorId } = formField;
  const roles = [
    {
      value: 'client',
      name: 'Client'
    },
    { value: 'operator', name: 'Operator' },
    { value: 'brand', name: 'Brand' }
  ];
  if (userRole === 'admin') {
    roles.push({ value: 'admin', name: 'Admin' });
  }
  let {
    firstName: firstNameV,
    lastName: lastNameV,
    email: emailV,
    password: passwordV,
    role: roleV,
    repeatPassword: repeatPasswordV,
    clientId: clientIdV,
    brandId: brandIdV,
    operatorId: operatorIdV
  } = values;

  useEffect(() => {
    if (!isSubmitting) {
      setRoleName('');
    }
  }, [isSubmitting]);

  const [roleName, setRoleName] = useState('');
  const [open, setOpen] = useState(false);

  const [clientOptions, setClientOptions] = useState([]);
  const [clientValue, setClientValue] = useState('');

  const handleClientInput = (event) => {
    if (event.target.value.length >= 2) {
      getAutocompleteClients(event.target.value).then((players) => {
        setClientOptions(players);
      });
    } else if (event.target.value.length < 2) {
      setClientOptions([]);
    }
  };

  const [operatorOptions, setOperatorOptions] = useState([]);
  const [operatorValue, setOperatorValue] = useState('');

  const handleOperatorInput = (event) => {
    if (event.target.value.length >= 2) {
      getAutocompleteOperators(event.target.value).then((players) => {
        setOperatorOptions(players);
      });
    } else if (event.target.value.length < 2) {
      setOperatorOptions([]);
    }
  };

  const [brandOptions, setBrandOptions] = useState([]);
  const [brandValue, setBrandValue] = useState('');

  const handleBrandInput = (event) => {
    if (event.target.value.length >= 2) {
      getAutocompleteBrands(event.target.value).then((players) => {
        setBrandOptions(players);
      });
    } else if (event.target.value.length < 2) {
      setBrandOptions([]);
    }
  };
  useEffect(() => {
    if (roleName === 'Client' && userRole === 'client') {
      values.clientId = client.id;
    }
  }, [roleName, userRole]);

  console.log(values);
  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant='h5'>Create user</MDTypography>
      </MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={firstName.type}
              label={firstName.label}
              name={firstName.name}
              value={firstNameV}
              placeholder={firstName.placeholder}
              error={errors.firstName && touched.firstName}
              success={firstNameV.length > 0 && !errors.firstName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={lastName.type}
              label={lastName.label}
              name={lastName.name}
              value={lastNameV}
              placeholder={lastName.placeholder}
              error={errors.lastName && touched.lastName}
              success={lastNameV.length > 0 && !errors.lastName}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <MDBox mb={1.5}>
              <Autocomplete
                open={open}
                onOpen={() => {
                  setOpen(true);
                }}
                onClose={() => {
                  setOpen(false);
                }}
                options={roles}
                inputValue={roleName}
                onChange={(e, value) => {
                  if (!value) {
                    setFieldValue(role.name, '');
                    setRoleName('');
                    return;
                  }
                  setFieldValue(role.name, value.value);
                  setRoleName(value.name);
                }}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                sx={{ width: '100%' }}
                renderInput={(params) => (
                  <Field
                    type={role.type}
                    {...params}
                    as={MDInput}
                    name={role.name}
                    value={roleV}
                    variant='standard'
                    label={role.label}
                    error={errors.role && touched.role}
                    success={roleV.length > 0 && !errors.role}
                    fullWidth
                  />
                )}
              />
              <MDBox mt={0.75}>
                <MDTypography component='div' variant='caption' color='error' fontWeight='regular'>
                  <ErrorMessage name={role.name} />
                </MDTypography>
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={email.type}
              label={email.label}
              name={email.name}
              value={emailV}
              placeholder={email.placeholder}
              error={errors.email && touched.email}
              success={emailV.length > 0 && !errors.email}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={password.type}
              label={password.label}
              name={password.name}
              value={passwordV}
              placeholder={password.placeholder}
              error={errors.password && touched.password}
              success={passwordV.length > 0 && !errors.password}
              inputProps={{ autoComplete: '' }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={repeatPassword.type}
              label={repeatPassword.label}
              name={repeatPassword.name}
              value={repeatPasswordV}
              placeholder={repeatPassword.placeholder}
              error={errors.repeatPassword && touched.repeatPassword}
              success={repeatPasswordV.length > 0 && !errors.repeatPassword}
              inputProps={{ autoComplete: '' }}
            />
          </Grid>
          {roleName === 'Client' && userRole === 'admin' && (
            <Grid item xs={12} sm={12}>
              <MDBox>
                <Autocomplete
                  limitTags={2}
                  options={clientOptions}
                  value={clientValue}
                  onChange={(e, value) => {
                    if (!value) {
                      setFieldValue(clientId.name, '');
                      setClientValue('');
                      return;
                    }
                    setFieldValue(clientId.name, value.value);
                    setClientValue(value.name);
                  }}
                  isOptionEqualToValue={(option, value) => option.name === value}
                  getOptionLabel={(option) => (option?.label ? option.label : option)}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                      {option.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <Field
                      type={clientId.type}
                      {...params}
                      as={MDInput}
                      name={clientId.name}
                      value={clientIdV}
                      variant='standard'
                      label={clientId.label}
                      error={errors.clientId && touched.clientId}
                      success={clientIdV.length > 0 && !errors.clientId}
                      onChange={handleClientInput}
                      fullWidth
                    />
                  )}
                />
              </MDBox>
            </Grid>
          )}

          {roleName === 'Brand' && (
            <Grid item xs={12} sm={12}>
              <MDBox>
                <Autocomplete
                  limitTags={2}
                  options={brandOptions}
                  value={brandValue}
                  onChange={(e, value) => {
                    if (!value) {
                      setFieldValue(clientId.name, '');
                      setBrandValue('');
                      return;
                    }
                    setFieldValue(brandId.name, value.value);
                    setBrandValue(value.name);
                  }}
                  isOptionEqualToValue={(option, value) => option.name === value}
                  getOptionLabel={(option) => (option?.label ? option.label : option)}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                      {option.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <Field
                      type={brandId.type}
                      {...params}
                      as={MDInput}
                      name={brandId.name}
                      value={brandIdV}
                      variant='standard'
                      label={brandId.label}
                      error={errors.brandId && touched.brandId}
                      success={brandIdV.length > 0 && !errors.brandId}
                      onChange={handleBrandInput}
                      fullWidth
                    />
                  )}
                />
              </MDBox>
            </Grid>
          )}

          {roleName === 'Operator' && (
            <Grid item xs={12} sm={12}>
              <MDBox>
                <Autocomplete
                  limitTags={2}
                  options={operatorOptions}
                  value={operatorValue}
                  onChange={(e, value) => {
                    if (!value) {
                      setFieldValue(clientId.name, '');
                      setOperatorValue('');
                      return;
                    }
                    setFieldValue(clientId.name, value.value);
                    setOperatorValue(value.name);
                  }}
                  isOptionEqualToValue={(option, value) => option.name === value}
                  getOptionLabel={(option) => (option?.label ? option.label : option)}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                      {option.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <Field
                      type={operatorId.type}
                      {...params}
                      as={MDInput}
                      name={operatorId.name}
                      value={operatorIdV}
                      variant='standard'
                      label={operatorId.label}
                      error={errors.operatorId && touched.operatorId}
                      success={operatorIdV.length > 0 && !errors.operatorId}
                      onChange={handleOperatorInput}
                      fullWidth
                    />
                  )}
                />
              </MDBox>
            </Grid>
          )}
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for UserInfo
UserInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired
};

export default UserInfo;
