import useAxios from 'hooks/useAxios';

export const getOperators = async (limit = 20, page = 1) => {
  const api = useAxios();
  try {
    const unformattedData = await api.get('/operator', {
      params: { limit: limit, page: page }
    });

    return {
      data: unformattedData.data.data,
      meta: unformattedData.data.meta
    };
  } catch (err) {
    console.log(err);
    return {
      data: [],
      meta: {
        totalItems: 0,
        itemCount: 0,
        itemsPerPage: 0,
        totalPages: 0,
        currentPage: 0
      }
    };
  }
};

export const getAutocompleteOperators = async (nickname) => {
  const api = useAxios();
  try {
    const unformattedData = await api.get('/operator/autocomplete', {
      params: {
        search: nickname
      }
    });

    const data = unformattedData.data.data.map((user) => {
      return {
        ...user,
        value: user?.id ? user.id : '-',
        label: user?.name ? user.name : '-'
      };
    });

    return data;
  } catch (err) {
    console.log(err);
    return {
      data: [],
      meta: {
        totalItems: 0,
        itemCount: 0,
        itemsPerPage: 0,
        totalPages: 0,
        currentPage: 0
      }
    };
  }
};

export const getOperator = async (id) => {
  const api = useAxios();
  try {
    const data = await api.get(`/operator/${id}`);

    return data.data;
  } catch (err) {
    console.log(err);
    return {
      data: {
        name: '',
        callbackUrl: '',
        whitelistedIps: []
      }
    };
  }
};

export const createOperator = async (operator) => {
  const api = useAxios();
  try {
    return await api.post('/operator', operator);
  } catch (err) {
    console.log(err);
    return {
      data: {
        action: '',
        object: ''
      }
    };
  }
};
export const updateOperator = async (id, name, callbackUrl) => {
  const api = useAxios();
  try {
    const params = {
      name: name,
      callbackUrl: callbackUrl
    };
    return await api.patch(`/operator/${id}`, params);
  } catch (err) {
    console.log(err);
    return {
      data: {
        action: '',
        object: ''
      }
    };
  }
};

export const deleteOperator = async (id) => {
  const api = useAxios();
  try {
    return await api.delete(`/operator/${id}`);
  } catch (err) {
    console.log(err);
    return {
      data: {
        action: '',
        object: ''
      }
    };
  }
};
