import useAxios from 'hooks/useAxios';

export const getBrands = async (limit = 20, page = 1) => {
  const api = useAxios();
  try {
    const unformattedData = await api.get('/brand', {
      params: { limit: limit, page: page }
    });

    return {
      data: unformattedData.data.data,
      meta: unformattedData.data.meta
    };
  } catch (err) {
    console.log(err);
    return {
      data: [],
      meta: {
        totalItems: 0,
        itemCount: 0,
        itemsPerPage: 0,
        totalPages: 0,
        currentPage: 0
      }
    };
  }
};

// export const getAutocompleteClients = async (nickname) => {
//   const api = useAxios();
//   try {
//     const unformattedData = await api.get('/client/autocomplete', {
//       params: {
//         search: nickname
//       }
//     });

//     const data = unformattedData.data.data.map((user) => {
//       return {
//         ...user,
//         value: user?.id ? user.id : '-',
//         label: user?.name ? user.name : '-'
//       };
//     });

//     return data;
//   } catch (err) {
//     console.log(err);
//     return {
//       data: [],
//       meta: {
//         totalItems: 0,
//         itemCount: 0,
//         itemsPerPage: 0,
//         totalPages: 0,
//         currentPage: 0
//       }
//     };
//   }
// };

export const getBrand = async (id) => {
  const api = useAxios();
  try {
    const data = await api.get(`/brand/${id}`);

    return data.data;
  } catch (err) {
    console.log(err);
    return {
      data: {
        name: '',
        callbackUrl: '',
        whitelistedIps: []
      }
    };
  }
};

export const createBrand = async (brand) => {
  const api = useAxios();
  try {
    const obj = {
      name: brand.name
    };
    if (brand.clientId) {
      obj.clientId = brand.clientId;
    }
    if (brand.operatorId) {
      obj.operatorId = brand.operatorId;
    }
    return await api.post('/brand', obj);
  } catch (err) {
    console.log(err);
    return {
      data: {
        action: '',
        object: ''
      }
    };
  }
};
export const updateClient = async (id, name, callbackUrl) => {
  const api = useAxios();
  try {
    const params = {
      name: name,
      callbackUrl: callbackUrl
    };
    return await api.patch(`/brand/${id}`, params);
  } catch (err) {
    console.log(err);
    return {
      data: {
        action: '',
        object: ''
      }
    };
  }
};

export const deleteBrand = async (id) => {
  const api = useAxios();
  try {
    return await api.delete(`/brand/${id}`);
  } catch (err) {
    console.log(err);
    return {
      data: {
        action: '',
        object: ''
      }
    };
  }
};

export const getAutocompleteBrands = async (nickname) => {
  const api = useAxios();
  try {
    const unformattedData = await api.get('/brand/autocomplete', {
      params: {
        search: nickname
      }
    });

    const data = unformattedData.data.data.map((user) => {
      return {
        ...user,
        value: user?.id ? user.id : '-',
        label: user?.name ? user.name : '-'
      };
    });

    return data;
  } catch (err) {
    console.log(err);
    return {
      data: [],
      meta: {
        totalItems: 0,
        itemCount: 0,
        itemsPerPage: 0,
        totalPages: 0,
        currentPage: 0
      }
    };
  }
};
