/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Icon from '@mui/material/Icon';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Material Dashboard 2 PRO React examples
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'components/DashboardNavbar';
import Footer from 'examples/Footer';
import { DateTimePicker } from '@mui/x-date-pickers';
import { pickersLayoutClasses } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import GradientLineChart from 'examples/Charts/LineCharts/GradientLineChart';
import {
  getGameSessions,
  getChartAmount,
  getStatistics,
  getEmailSummary,
  ggrForPeriod,
  ggrForPeriodAverage,
  getCountryChart
} from 'services/analytics';
import { useEffect, useState, useRef } from 'react';
import { Card, colors, Select, MenuItem, Skeleton, InputLabel } from '@mui/material';
import HorizontalBarChart from 'examples/Charts/BarCharts/HorizontalBarChart';
import { Chart } from 'react-google-charts';
import { CardGiftcard, TocTwoTone } from '@mui/icons-material';
import PieChart from 'examples/Charts/PieChart';
import { columnData } from './columnData';
import { getLeaderboard } from 'services/leaderboard/get-leaderboard';
import DataTable from 'components/DataTablePage/components/DataTable';
import { FormControl } from '@mui/base';
import { getGames } from 'services/games';

import InfiniteScroll from 'react-infinite-scroll-component';
import { Can } from 'context';

function Dashboard() {
  const [gameWins, setGameWins] = useState([]);
  const [gameLoses, setGameLoses] = useState([]);
  const [correctMonths, setCorrectMonths] = useState([]);
  const [newRegistrations, setNewRegistrations] = useState([]);
  const [gameSessions, setGameSessions] = useState([]);
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const [statistics, setStatistics] = useState([]);
  const [profit, setProfit] = useState(0);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [summaryFrom, setSummaryFrom] = useState();
  const [summaryTo, setSummaryTo] = useState();
  const [summary, setSummary] = useState({});

  const [fromGGR, setFromGGR] = useState(null);
  const [toGGR, setToGGR] = useState(null);
  const [ggr, setGGR] = useState(null);
  const [average, setAverage] = useState(null);

  const [fromChart, setFromChart] = useState(null);
  const [toChart, setToChart] = useState(null);
  const [fromTable, setFromTable] = useState(null);
  const [toTable, setToTable] = useState(null);
  const [countries, setCountries] = useState([]);
  const [countryCounts, setCountryCounts] = useState([]);

  const [game, setGame] = useState(191841);
  const [gamesList, setGamesList] = useState([]);
  const [page, setPage] = useState(1); // Tracks pagination
  const [hasMore, setHasMore] = useState(true); // Controls infinite scrolling
  const [loading, setLoading] = useState(false); // Loading state
  const [isOpen, setIsOpen] = useState(false); // Dropdown visibility
  const dropdownRef = useRef(null); // Ref for detecting outside clicks

  const gameName = gamesList.filter((e) => e?.id === game)[0];

  const [filters, setFilters] = useState({
    dateStart: fromTable,
    dateEnd: toTable,
    game
  });

  async function fetchGames() {
    const res = await getGames(20, page);

    if (res.data.length === 0) {
      setHasMore(false);
      return;
    }
    setGamesList((prev) => [...prev, ...res.data]);
    setPage((prev) => prev + 1);
  }

  useEffect(() => {
    // call on initial load
    fetchGames();
  }, []);

  const handleFromChange = (date, setterFrom, setterTo) => {
    if (to) {
      if (date > to) {
        setterFrom(to);
        setterTo(date);
      } else {
        setterFrom(date);
      }
    } else {
      setterFrom(new Date(date));
    }
  };
  const handleToChange = (date, setterFrom, setterTo) => {
    if (from) {
      if (date < from) {
        setterFrom(date);
        setterTo(from);
      } else {
        setterTo(date);
      }
    } else {
      setterTo(new Date(date));
    }
  };

  const handleFromChangeGGR = (date, setterFrom, setterTo) => {
    if (toGGR) {
      if (date > toGGR) {
        setterFrom(toGGR);
        setterTo(date);
      } else {
        setterFrom(date);
      }
    } else {
      setterFrom(new Date(date));
    }
  };

  const handleToChangeGGR = (date, setterFrom, setterTo) => {
    if (fromGGR) {
      if (date < fromGGR) {
        setterFrom(date);
        setterTo(fromGGR);
      } else {
        setterTo(date);
      }
    } else {
      setterTo(new Date(date));
    }
  };

  const handleFromChangeChart = (date, setterFrom, setterTo) => {
    if (toChart) {
      if (date > toChart) {
        setterFrom(toChart);
        setterTo(date);
      } else {
        setterFrom(date);
      }
    } else {
      setterFrom(new Date(date));
    }
  };

  const handleToChangeChart = (date, setterFrom, setterTo) => {
    if (fromChart) {
      if (date < fromChart) {
        setterFrom(date);
        setterTo(fromChart);
      } else {
        setterTo(date);
      }
    } else {
      setterTo(new Date(date));
    }
  };

  const handleFromChangeTable = (date, setterFrom, setterTo) => {
    if (toTable) {
      if (date > toTable) {
        setterFrom(toTable);
        setterTo(date);
      } else {
        setterFrom(date);
      }
    } else {
      setterFrom(new Date(date));
    }
  };

  const handleToChangeTable = (date, setterFrom, setterTo) => {
    if (fromTable) {
      if (date < fromTable) {
        setterFrom(date);
        setterTo(fromTable);
      } else {
        setterTo(date);
      }
    } else {
      setterTo(new Date(date));
    }
  };

  useEffect(() => {
    getStatistics(from, to).then((res) => setStatistics(res.data));
  }, [from, to]);

  useEffect(() => {
    getEmailSummary(summaryFrom, summaryTo).then((res) => setSummary(res.data));
  }, [summaryFrom, summaryTo]);

  useEffect(() => {
    ggrForPeriod(fromGGR, toGGR).then((res) => setGGR(res?.data));
  }, [fromGGR, toGGR]);

  const gameData = {
    labels: correctMonths,
    datasets: [
      {
        label: 'Wins',
        data: gameWins,
        color: 'info'
      },
      {
        label: 'Losses',
        data: gameLoses
      }
    ]
  };

  const sessionsData = {
    labels: ['Daily bets'],
    datasets: [
      {
        label: 'Bets',
        data: gameSessions,
        color: 'info'
      }
    ]
  };

  useEffect(() => {
    getChartAmount('win').then((res) => {
      let wins = res.map((m) => {
        m[0] === 12
          ? setCorrectMonths((prev) => [...prev, months[0]])
          : setCorrectMonths((prev) => [...prev, months[m[0]]]);
        return m[1];
      });
      setGameWins(wins);
    });
    getChartAmount('lose').then((res) => {
      let loses = res.map((m) => {
        return m[1];
      });
      setGameLoses(loses);
    });
  }, []);

  useEffect(() => {
    getGameSessions().then((res) => setGameSessions(res));
    getStatistics().then((res) => setStatistics(res.data));
  }, []);

  useEffect(() => {
    let countProfit = 0;
    statistics?.profitForPeriod?.map((profit) => {
      countProfit = Number(countProfit) + Number(profit.profit);
    });
    setProfit(countProfit.toFixed(2));
  }, [statistics]);

  useEffect(() => {
    ggrForPeriodAverage(fromGGR, toGGR).then((res) => setAverage(res.data));
  }, [fromGGR, toGGR]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getCountryChart(fromChart, toChart);

        // Initialize temporary arrays for countries and country counts
        const countriesArray = [];
        const countryCountsArray = [];

        if (Array.isArray(res.data)) {
          res.data.forEach((e) => {
            countriesArray.push(e.country);
            countryCountsArray.push(Number(e.country_count));
          });
        } else if (res.data) {
          countriesArray.push(res.data.country);
          countryCountsArray.push(Number(res.data.country_count));
        }

        // Set state for countries and country counts
        setCountries(countriesArray);
        setCountryCounts(countryCountsArray);
      } catch (error) {
        console.error('Error fetching chart data:', error);
      }
    };

    fetchData();
  }, [fromChart, toChart]);
  const options = {
    title: 'Player countries'
  };

  useEffect(() => {
    setFilters(() => ({
      dateStart: fromTable,
      dateEnd: toTable,
      game
    }));
  }, [fromTable, toTable, game]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox mt={5}>
          <Grid container spacing={3} direction='row' justify='center' alignItems='stretch'>
            <Grid item xs={8}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {gameWins.length > 0 ? (
                    <GradientLineChart
                      title='Game Wins vs. Losses ($)'
                      description='Monthly performance'
                      chart={gameData}
                      tension={0.5}
                    />
                  ) : (
                    <Skeleton height={400} />
                  )}
                </Grid>
                <Grid item xs={12}>
                  {gameSessions.length > 0 ? (
                    <HorizontalBarChart title='Daily bets' description='24 Hours performance' chart={sessionsData} />
                  ) : (
                    <Skeleton height={400} />
                  )}
                </Grid>
                <Grid item>
                  <Card>
                    <MDBox
                      sx={{ display: 'flex', justifyContent: 'center', gap: '10px' }}
                      borderRadius='lg'
                      p={3}
                      mb={2}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          label='From'
                          ampmInClock={false}
                          closeOnSelect={true}
                          ampm={false}
                          showDaysOutsideCurrentMonth
                          value={fromChart}
                          viewRenderers={{
                            hours: null,
                            minutes: null,
                            seconds: null
                          }}
                          onChange={(e) => {
                            handleFromChangeTable(e.$d, setFromTable, setToTable);
                          }}
                          slotProps={{
                            layout: {
                              sx: {
                                [`.${pickersLayoutClasses.actionBar}`]: {
                                  display: 'none'
                                }
                              }
                            }
                          }}
                        />
                        <DateTimePicker
                          label='To'
                          ampmInClock={false}
                          closeOnSelect={true}
                          ampm={false}
                          showDaysOutsideCurrentMonth
                          value={toChart}
                          viewRenderers={{
                            hours: null,
                            minutes: null,
                            seconds: null
                          }}
                          onChange={(e) => {
                            handleToChangeTable(e.$d, setFromTable, setToTable);
                          }}
                          slotProps={{
                            layout: {
                              sx: {
                                [`.${pickersLayoutClasses.actionBar}`]: {
                                  display: 'none'
                                }
                              }
                            }
                          }}
                        />
                      </LocalizationProvider>
                      <div ref={dropdownRef} style={{ position: 'relative', width: '200px' }}>
                        {/* ✅ Button to open dropdown */}
                        <div
                          onClick={() => setIsOpen(!isOpen)}
                          style={{
                            padding: '10px',
                            border: '1px solid #ccc',
                            cursor: 'pointer',
                            borderRadius: '5px',
                            background: '#fff'
                          }}
                        >
                          {game ? gameName?.name : 'Select a game'}
                        </div>

                        {/* ✅ Dropdown menu */}
                        {isOpen && (
                          <ul
                            id='games'
                            // onScroll={handleScroll} // Detects scrolling
                            style={{
                              position: 'absolute',
                              top: '100%',
                              left: 0,
                              width: '100%',
                              maxHeight: '200px',
                              overflowY: 'auto',
                              background: '#fff',
                              border: '1px solid #ccc',
                              borderRadius: '5px',
                              listStyle: 'none',
                              padding: 0,
                              margin: 0,
                              zIndex: 10
                            }}
                          >
                            {/* ✅ Render game items */}
                            <InfiniteScroll
                              dataLength={gamesList.length}
                              next={fetchGames}
                              hasMore={hasMore}
                              scrollableTarget='games'
                            >
                              {gamesList.map((gameItem) => (
                                <li
                                  key={gameItem.id}
                                  onClick={() => {
                                    setGame(gameItem?.id);
                                    setIsOpen(false);
                                  }}
                                  style={{
                                    padding: '10px',
                                    cursor: 'pointer',
                                    borderBottom: '1px solid #eee',
                                    background: gameItem.id === game ? '#e5e5e5' : '#fff'
                                  }}
                                >
                                  {gameItem.name}
                                </li>
                              ))}
                            </InfiniteScroll>

                            {/* ✅ Loading indicator */}
                            {loading && (
                              <li style={{ padding: '10px', textAlign: 'center' }}>
                                <CircularProgress size={24} />
                              </li>
                            )}
                          </ul>
                        )}
                      </div>
                    </MDBox>
                    <DataTable
                      fetchData={getLeaderboard}
                      filters={filters}
                      columnData={columnData}
                      noActions
                    ></DataTable>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card>
                    <MDBox
                      sx={{ display: 'flex', justifyContent: 'center', gap: '10px' }}
                      borderRadius='lg'
                      p={3}
                      mb={2}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          label='From'
                          ampmInClock={false}
                          closeOnSelect={true}
                          ampm={false}
                          showDaysOutsideCurrentMonth
                          value={fromChart}
                          viewRenderers={{
                            hours: null,
                            minutes: null,
                            seconds: null
                          }}
                          onChange={(e) => {
                            handleFromChangeChart(e.$d, setFromChart, setToChart);
                          }}
                          slotProps={{
                            layout: {
                              sx: {
                                [`.${pickersLayoutClasses.actionBar}`]: {
                                  display: 'none'
                                }
                              }
                            }
                          }}
                        />
                        <DateTimePicker
                          label='To'
                          ampmInClock={false}
                          closeOnSelect={true}
                          ampm={false}
                          showDaysOutsideCurrentMonth
                          value={toChart}
                          viewRenderers={{
                            hours: null,
                            minutes: null,
                            seconds: null
                          }}
                          onChange={(e) => {
                            handleToChangeChart(e.$d, setFromChart, setToChart);
                          }}
                          slotProps={{
                            layout: {
                              sx: {
                                [`.${pickersLayoutClasses.actionBar}`]: {
                                  display: 'none'
                                }
                              }
                            }
                          }}
                        />
                      </LocalizationProvider>
                    </MDBox>
                    <MDBox sx={{ display: 'flex', justifyContent: 'center' }}>
                      <MDTypography fontSize='13px'>
                        {fromChart || toChart
                          ? `Showing results from ${
                              fromChart ? new Date(fromChart).toLocaleDateString() : 'the beggining'
                            } to ${toChart ? new Date(toChart).toLocaleDateString() : 'now'}`
                          : 'Showing results for all the time'}{' '}
                      </MDTypography>
                    </MDBox>
                    <PieChart
                      // icon={{ color: 'info', component: 'leaderboard' }}
                      title='Player countries'
                      description='Analytics Insights'
                      chart={{
                        labels: countries,
                        datasets: {
                          label: 'Projects',
                          backgroundColors: ['info', 'primary', 'dark', 'secondary', 'primary'],
                          data: countryCounts
                        },
                        options: {
                          plugins: {
                            legend: {
                              display: true
                            }
                          }
                        }
                      }}
                    />
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Card>
                    <MDBox position='relative' borderRadius='lg' p={3}>
                      <MDBox mb={1}>
                        <MDTypography
                          variant='h5'
                          fontWeight='bold'
                          textTransform='capitalize'
                          fontSize='1.2rem'
                          mb={2}
                        >
                          Statistics
                        </MDTypography>
                        <MDBox sx={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }} mb={2}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                              label='From'
                              ampmInClock={false}
                              closeOnSelect={true}
                              ampm={false}
                              showDaysOutsideCurrentMonth
                              value={from}
                              viewRenderers={{
                                hours: null,
                                minutes: null,
                                seconds: null
                              }}
                              onChange={(e) => {
                                handleFromChange(e.$d, setFrom, setTo);
                              }}
                              slotProps={{
                                layout: {
                                  sx: {
                                    [`.${pickersLayoutClasses.actionBar}`]: {
                                      display: 'none'
                                    }
                                  }
                                }
                              }}
                            />
                            <DateTimePicker
                              label='To'
                              ampmInClock={false}
                              closeOnSelect={true}
                              ampm={false}
                              showDaysOutsideCurrentMonth
                              value={to}
                              viewRenderers={{
                                hours: null,
                                minutes: null,
                                seconds: null
                              }}
                              onChange={(e) => {
                                handleToChange(e.$d, setFrom, setTo);
                              }}
                              slotProps={{
                                layout: {
                                  sx: {
                                    [`.${pickersLayoutClasses.actionBar}`]: {
                                      display: 'none'
                                    }
                                  }
                                }
                              }}
                            />
                          </LocalizationProvider>
                        </MDBox>
                        <MDTypography fontSize='13px'>
                          {from || to
                            ? `Showing results from ${
                                from ? new Date(from).toLocaleDateString() : 'the beggining'
                              } to ${to ? new Date(to).toLocaleDateString() : 'now'}`
                            : 'Showing results for all the time'}{' '}
                        </MDTypography>
                      </MDBox>
                      <MDBox>
                        <MDBox sx={{ display: 'flex', gap: '5px' }}>
                          <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                            Bets:
                          </MDTypography>
                          <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                            {` ${statistics?.betsForPeriod ? statistics?.betsForPeriod : 0}`}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                      <MDBox>
                        <MDBox sx={{ display: 'flex', gap: '5px' }}>
                          <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                            Bets (Amount):
                          </MDTypography>
                          <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                            {` ${
                              statistics?.betsForPeriodAmount?.length > 0
                                ? statistics?.betsForPeriodAmount[0]?.amount
                                  ? statistics?.betsForPeriodAmount[0]?.amount
                                  : 0
                                : statistics?.betsForPeriodAmount?.amount
                            }`}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                      <MDBox>
                        <MDBox sx={{ display: 'flex', gap: '5px' }}>
                          <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                            Non-rollbacked bets:
                          </MDTypography>
                          <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                            {` ${statistics?.nonRollbackedBetsForPeriod ? statistics?.nonRollbackedBetsForPeriod : 0}`}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                      <MDBox>
                        <MDBox sx={{ display: 'flex', gap: '5px' }}>
                          <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                            Non-rollbacked bets (Amount):
                          </MDTypography>

                          <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                            {` ${
                              statistics?.nonRollbackedBetsForPeriodAmount?.length > 0
                                ? statistics?.nonRollbackedBetsForPeriodAmount[0]?.amount
                                  ? statistics?.nonRollbackedBetsForPeriodAmount[0]?.amount
                                  : 0
                                : statistics?.nonRollbackedBetsForPeriodAmount?.amount
                            }`}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                      <MDBox>
                        <MDBox sx={{ display: 'flex', gap: '5px' }}>
                          <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                            Profit:
                          </MDTypography>
                          <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                            {` ${profit}`}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid>
                <Can I='manage' a='email_metrics'>
                  <Grid item xs={12}>
                    <Card>
                      <MDBox position='relative' borderRadius='lg' p={3}>
                        <MDBox mb={1}>
                          <MDTypography
                            variant='h5'
                            fontWeight='bold'
                            textTransform='capitalize'
                            fontSize='1.2rem'
                            mb={2}
                          >
                            Email Metric Summary
                          </MDTypography>
                          <MDBox sx={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }} mb={2}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                label='From'
                                ampmInClock={false}
                                closeOnSelect={true}
                                ampm={false}
                                showDaysOutsideCurrentMonth
                                value={summaryFrom}
                                viewRenderers={{
                                  hours: null,
                                  minutes: null,
                                  seconds: null
                                }}
                                onChange={(e) => {
                                  handleFromChange(new Date(e.$d).toDateString(), setSummaryFrom, setSummaryTo);
                                }}
                                slotProps={{
                                  layout: {
                                    sx: {
                                      [`.${pickersLayoutClasses.actionBar}`]: {
                                        display: 'none'
                                      }
                                    }
                                  }
                                }}
                              />
                              <DateTimePicker
                                label='To'
                                ampmInClock={false}
                                closeOnSelect={true}
                                ampm={false}
                                showDaysOutsideCurrentMonth
                                value={summaryTo}
                                viewRenderers={{
                                  hours: null,
                                  minutes: null,
                                  seconds: null
                                }}
                                onChange={(e) => {
                                  handleToChange(new Date(e.$d).toDateString(), setSummaryFrom, setSummaryTo);
                                }}
                                slotProps={{
                                  layout: {
                                    sx: {
                                      [`.${pickersLayoutClasses.actionBar}`]: {
                                        display: 'none'
                                      }
                                    }
                                  }
                                }}
                              />
                            </LocalizationProvider>
                          </MDBox>
                          <MDTypography fontSize='13px'>
                            {summaryFrom || summaryTo
                              ? `Showing results from ${
                                  summaryFrom ? new Date(summaryFrom).toLocaleDateString() : 'the beggining'
                                } to ${summaryTo ? new Date(summaryTo).toLocaleDateString() : 'now'}`
                              : 'Showing results for all the time'}{' '}
                          </MDTypography>
                        </MDBox>
                        {Object?.entries(summary)?.map((game, index) => {
                          return (
                            <MDBox
                              key={index}
                              mb={index + 1 !== Object?.entries(summary).length && 2}
                              sx={{ border: '0.3px solid white', borderRadius: '12px', padding: '15px' }}
                            >
                              <MDTypography
                                sx={{
                                  fontWeight: 800,
                                  borderBottom: '1px solid white',
                                  width: '100%'
                                }}
                              >
                                {game[0]}
                              </MDTypography>
                              <MDBox mt={2}>
                                <MDBox sx={{ display: 'flex', gap: '5px' }}>
                                  <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                                    Player Count:
                                  </MDTypography>
                                  <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                                    {` ${game[1]?.playerCount ? game[1]?.playerCount : 0}`}
                                  </MDTypography>
                                </MDBox>
                              </MDBox>
                              <MDBox>
                                <MDBox sx={{ display: 'flex', gap: '5px' }}>
                                  <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                                    Bets:
                                  </MDTypography>
                                  <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                                    {` ${game[1]?.betCount ? game[1]?.betCount : 0}`}
                                  </MDTypography>
                                </MDBox>
                              </MDBox>
                              <MDBox>
                                <MDBox sx={{ display: 'flex', gap: '5px' }}>
                                  <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                                    Bet Average:
                                  </MDTypography>
                                  <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                                    {` ${game[1]?.betAverage ? game[1]?.betAverage : 0}`}
                                  </MDTypography>
                                </MDBox>
                              </MDBox>
                              <MDBox>
                                <MDBox sx={{ display: 'flex', gap: '5px' }}>
                                  <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                                    Bets (Amount):
                                  </MDTypography>
                                  <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                                    {` ${game[1]?.betSum ? game[1]?.betSum : 0}`}
                                  </MDTypography>
                                </MDBox>
                              </MDBox>
                              <MDBox>
                                <MDBox sx={{ display: 'flex', gap: '5px' }}>
                                  <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                                    Non-rollbacked bets:
                                  </MDTypography>
                                  <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                                    {` ${game[1]?.nonRolledBackBetsCount ? game[1]?.nonRolledBackBetsCount : 0}`}
                                  </MDTypography>
                                </MDBox>
                              </MDBox>
                              <MDBox>
                                <MDBox sx={{ display: 'flex', gap: '5px' }}>
                                  <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                                    Non-rollbacked bets (Amount):
                                  </MDTypography>
                                  <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                                    {` ${game[1]?.nonRollbackedBetsAmount ? game[1]?.nonRollbackedBetsAmount : 0}`}
                                  </MDTypography>
                                </MDBox>
                              </MDBox>
                              <MDBox>
                                <MDBox sx={{ display: 'flex', gap: '5px' }}>
                                  <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                                    Percent Rolled Back Bets:
                                  </MDTypography>
                                  <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                                    {` ${
                                      game[1]?.percentRolledBackBets ? game[1]?.percentRolledBackBets.toFixed(2) : 0
                                    }`}
                                  </MDTypography>
                                </MDBox>
                              </MDBox>
                              <MDBox>
                                <MDBox sx={{ display: 'flex', gap: '5px' }}>
                                  <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                                    Average Rolled Back Bets (Amount):
                                  </MDTypography>
                                  <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                                    {` ${
                                      game[1]?.averageRollbackedBetsAmount ? game[1]?.averageRollbackedBetsAmount : 0
                                    }`}
                                  </MDTypography>
                                </MDBox>
                              </MDBox>
                            </MDBox>
                          );
                        })}
                      </MDBox>
                    </Card>
                  </Grid>
                </Can>
                <Can I='read' a='ggr'>
                  <Grid item xs={12}>
                    <Card>
                      <MDBox position='relative' borderRadius='lg' p={3}>
                        <MDBox mb={1}>
                          <MDTypography
                            variant='h5'
                            fontWeight='bold'
                            textTransform='capitalize'
                            fontSize='1.2rem'
                            mb={2}
                          >
                            GGR & Average Metrics
                          </MDTypography>
                          <MDBox sx={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }} mb={2}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                label='From'
                                ampmInClock={false}
                                closeOnSelect={true}
                                ampm={false}
                                showDaysOutsideCurrentMonth
                                value={fromGGR}
                                viewRenderers={{
                                  hours: null,
                                  minutes: null,
                                  seconds: null
                                }}
                                onChange={(e) => {
                                  handleFromChangeGGR(e.$d, setFromGGR, setToGGR);
                                }}
                                slotProps={{
                                  layout: {
                                    sx: {
                                      [`.${pickersLayoutClasses.actionBar}`]: {
                                        display: 'none'
                                      }
                                    }
                                  }
                                }}
                              />
                              <DateTimePicker
                                label='To'
                                ampmInClock={false}
                                closeOnSelect={true}
                                ampm={false}
                                showDaysOutsideCurrentMonth
                                value={toGGR}
                                viewRenderers={{
                                  hours: null,
                                  minutes: null,
                                  seconds: null
                                }}
                                onChange={(e) => {
                                  handleToChangeGGR(e.$d, setFromGGR, setToGGR);
                                }}
                                slotProps={{
                                  layout: {
                                    sx: {
                                      [`.${pickersLayoutClasses.actionBar}`]: {
                                        display: 'none'
                                      }
                                    }
                                  }
                                }}
                              />
                            </LocalizationProvider>
                          </MDBox>
                          <MDTypography fontSize='13px'>
                            {fromGGR || toGGR
                              ? `Showing results from ${
                                  fromGGR ? new Date(fromGGR).toLocaleDateString() : 'the beggining'
                                } to ${toGGR ? new Date(toGGR).toLocaleDateString() : 'now'}`
                              : 'Showing results for all the time'}{' '}
                          </MDTypography>
                        </MDBox>
                        <MDBox style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <MDBox sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                            <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                              GGR:
                            </MDTypography>
                            <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                              <Grid container style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                {Array.isArray(ggr) ? (
                                  ggr?.map((e, index) => (
                                    <Grid key={index}>
                                      <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                                        {e?.client}
                                      </MDTypography>
                                      :{' '}
                                      <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                                        {e?.ggr}
                                      </MDTypography>
                                    </Grid>
                                  ))
                                ) : (
                                  <Grid>
                                    <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                                      {ggr?.client}
                                    </MDTypography>
                                    :{' '}
                                    <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                                      {ggr?.ggr}
                                    </MDTypography>
                                  </Grid>
                                )}
                              </Grid>
                            </MDTypography>
                          </MDBox>
                          <MDBox sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                            <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                              Average Metrics:
                            </MDTypography>
                            <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                              <Grid container style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                {Array.isArray(average) ? (
                                  average.map((e, index) => (
                                    <Grid
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '5px',
                                        paddingBottom: '10px'
                                      }}
                                      key={index}
                                    >
                                      <Grid>
                                        <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                                          client:{' '}
                                        </MDTypography>
                                        <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                                          {e?.client}
                                        </MDTypography>
                                      </Grid>
                                      <Grid>
                                        <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                                          bet:{' '}
                                        </MDTypography>
                                        <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                                          {e?.bet.toFixed(2)}
                                        </MDTypography>
                                      </Grid>
                                      <Grid>
                                        <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                                          rake:{' '}
                                        </MDTypography>
                                        <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                                          {e?.rake.toFixed(2)}
                                        </MDTypography>
                                      </Grid>
                                      <Grid>
                                        <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                                          win:{' '}
                                        </MDTypography>
                                        <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                                          {e?.win.toFixed(2)}
                                        </MDTypography>
                                      </Grid>
                                    </Grid>
                                  ))
                                ) : (
                                  <Grid
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      gap: '5px',
                                      paddingBottom: '10px'
                                    }}
                                  >
                                    <Grid>
                                      <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                                        client:{' '}
                                      </MDTypography>
                                      <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                                        {average?.client}
                                      </MDTypography>
                                    </Grid>
                                    <Grid>
                                      <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                                        bet:{' '}
                                      </MDTypography>
                                      <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                                        {average?.bet}
                                      </MDTypography>
                                    </Grid>
                                    <Grid>
                                      <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                                        rake:{' '}
                                      </MDTypography>
                                      <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                                        {average?.rake}
                                      </MDTypography>
                                    </Grid>
                                    <Grid>
                                      <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                                        win:{' '}
                                      </MDTypography>
                                      <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                                        {average?.win}
                                      </MDTypography>
                                    </Grid>
                                  </Grid>
                                )}
                              </Grid>
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </Card>
                  </Grid>
                </Can>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
