const brandColumnData = [
  {
    Header: 'Id',
    accessor: 'id'
  },
  {
    Header: 'Name',
    accessor: 'name'
  },
  {
    Header: 'Operator',
    accessor: 'operator',
    Cell: ({ value }) => {
      return value?.name ? value.name : '-';
    }
  },
  {
    Header: 'Client',
    accessor: 'client',
    Cell: ({ value }) => {
      return value?.name ? value.name : '-';
    }
  },
  {
    Header: 'Created At',
    accessor: 'createdAt'
  },
  {
    Header: 'Updated At',
    accessor: 'updatedAt'
  }
];

export default brandColumnData;
